import React, { useState, useEffect } from "react";
import axios from "axios";

const AddressPage = () => {
  const [addressData, setAddressData] = useState([]); // Store multiple addresses
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Track if editing an address
  const [isModalOpen, setIsModalOpen] = useState(false); // Track if modal is open
  const [newAddressData, setNewAddressData] = useState({
    fullName: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [editingAddressId, setEditingAddressId] = useState(null); // Track which address is being edited

  // Fetch all addresses when component mounts
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    if (!storedUserData || !storedUserData._id) {
      setError("No user ID found in localStorage.");
      setLoading(false);
      return;
    }

    const addressId = storedUserData._id;

    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          `https://avantdivine.com/api/address/${storedUserData._id}`
        );
        if (response.data.success) {
          setAddressData(response.data.data); // Set multiple addresses
        } else {
          setError("Failed to fetch addresses.");
        }
      } catch (err) {
        setError("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, []);

  const handleInputChange = (e) => {
    setNewAddressData({ ...newAddressData, [e.target.name]: e.target.value });
  };

  const handleEdit = (address) => {
    setIsEditing(true);
    setNewAddressData(address); // Pre-fill form with the existing address data
    setEditingAddressId(address._id); // Track the address being edited
    setIsModalOpen(true); // Open modal when editing
  };

  const handleSave = async () => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const addressId = storedUserData._id;

      // Make API request to update the address
      const response = await axios.put(
        `https://avantdivine.com/api/address/${addressId}/${editingAddressId}`, // Update specific address
        newAddressData
      );

      if (response.data.success) {
        // Trigger a re-fetch to get the latest address list
        const updatedResponse = await axios.get(
          `https://avantdivine.com/api/address/${addressId}`
        );
        if (updatedResponse.data.success) {
          setAddressData(updatedResponse.data.data); // Update the address list with the latest data
          setIsModalOpen(false); // Close modal after saving
          alert("Address updated successfully");
        } else {
          alert("Failed to fetch updated address list.");
        }
      } else {
        alert("Failed to update address");
      }
    } catch (err) {
      console.error(err);
      alert("Error updating address");
    }
  };

  const handleDelete = async (addressId) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData._id;

      const response = await axios.delete(
        `https://avantdivine.com/api/address/${userId}/${addressId}`
      );
      if (response.data.success) {
        alert("Address deleted successfully");
        // Remove the deleted address from the list
        setAddressData(
          addressData.filter((address) => address._id !== addressId)
        );
      } else {
        alert("Failed to delete address");
      }
    } catch (err) {
      console.error(err);
      alert("Error deleting address");
    }
  };

  const handleAddNewAddress = async () => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData._id;

      const response = await axios.post(
        `https://avantdivine.com/api/address/${userId}`,
        newAddressData
      );
      if (response.data.success) {
        alert("Address added successfully");
        setAddressData([...addressData, response.data.data]); // Add new address to the list
        setNewAddressData({
          fullName: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
        });
        setIsModalOpen(false); // Close modal after adding
      } else {
        alert("Failed to add address");
      }
    } catch (err) {
      console.error(err);
      alert("Error adding new address");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };

  if (loading) {
    return <div className="text-center text-xl text-gray-500">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-xl text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-6">
      <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
        Address Details
      </h1>

      {addressData.length > 0 ? (
        addressData.map((address) => (
          <div
            key={address._id}
            className="bg-white shadow-lg rounded-lg p-6 mb-6"
          >
            <h2 className="text-2xl font-bold text-gray-700">
              {address.fullName}
            </h2>
            <p className="text-gray-600 mt-2">
              <strong>Phone Number:</strong> {address.phoneNumber}
            </p>
            <p className="text-gray-600 mt-2">
              <strong>Address:</strong> {address.address}
            </p>
            <p className="text-gray-600 mt-2">
              <strong>City:</strong> {address.city}
            </p>
            <p className="text-gray-600 mt-2">
              <strong>State:</strong> {address.state}
            </p>
            <p className="text-gray-600 mt-2">
              <strong>Postal Code:</strong> {address.postalCode}
            </p>

            <div className="flex justify-between mt-6">
              <button
                className="text-blue-500"
                onClick={() => handleEdit(address)}
              >
                Edit
              </button>
              <button
                className="text-red-500"
                onClick={() => handleDelete(address._id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-600">No address found.</p>
      )}

      {/* Add New Address Button */}
      <div className="text-center mb-6">
        <button
          onClick={() => {
            setIsEditing(false); // Set to false to add a new address
            setIsModalOpen(true); // Open the modal for adding new address
          }}
          className="w-full p-2 bg-[#191919] text-white rounded mt-4"
        >
          + Add New Address
        </button>
      </div>

      {/* Modal for Adding or Editing Address */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-full max-w-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              {isEditing ? "Edit Address" : "Add New Address"}
            </h3>
            <form>
              <input
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                type="text"
                name="fullName"
                value={newAddressData.fullName}
                onChange={handleInputChange}
                placeholder="Full Name"
              />
              <input
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                type="text"
                name="phoneNumber"
                value={newAddressData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Phone Number"
              />
              <input
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                type="text"
                name="address"
                value={newAddressData.address}
                onChange={handleInputChange}
                placeholder="Address"
              />
              <input
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                type="text"
                name="city"
                value={newAddressData.city}
                onChange={handleInputChange}
                placeholder="City"
              />
              <input
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                type="text"
                name="state"
                value={newAddressData.state}
                onChange={handleInputChange}
                placeholder="State"
              />
              <input
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                type="text"
                name="postalCode"
                value={newAddressData.postalCode}
                onChange={handleInputChange}
                placeholder="Postal Code"
              />
              <div className="flex justify-between">
                <button
                  type="button"
                  className="p-2 bg-gray-500 text-white rounded"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="p-2 bg-blue-500 text-white rounded"
                  onClick={isEditing ? handleSave : handleAddNewAddress}
                >
                  {isEditing ? "Save Changes" : "Add Address"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressPage;
