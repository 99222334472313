import React, { useEffect, useState } from "react";

export default function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]); // Store products fetched from the API
  const [loading, setLoading] = useState(true);

  const IMAGE_BASE_URL = "https://avantdivine.com/api/uploads"; // Base URL for images

  // Fetch all products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/products/all"
        );
        const data = await response.json();

        if (data && Array.isArray(data)) {
          // Map API response to the desired format
          const formattedProducts = data.map((item) => ({
            id: item._id,
            name: item.name,
            price: item.price,
            image: process.env.REACT_APP_IMAGE_URL + "/" + item.images[0], // Get image URL
          }));
          setProducts(formattedProducts); // Store products in state
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Fetch orders data from the API
  useEffect(() => {
    const fetchOrders = async () => {
      const storedUserData = JSON.parse(localStorage.getItem("user")); // Retrieve user data from localStorage

      if (!storedUserData || !storedUserData._id) {
        console.error("User data or user ID not found in localStorage");
        setLoading(false);
        return;
      }

      const userId = storedUserData._id; // Get the user ID from the stored user data

      try {
        const response = await fetch(
          `http://localhost:5000/api/orders/user/${userId}`
        );
        const data = await response.json();

        if (data.success) {
          setOrders(data.data); // Assuming response data is in 'data' field
        }
      } catch (error) {
        console.error("Error fetching orders", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Function to get product image by matching productId
  const getProductImage = (productId) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.image : "https://via.placeholder.com/150"; // Fallback image if no match found
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-12">YOUR ORDERS</h1>

      {/* Order Items */}
      <div className="space-y-12">
        {orders.map((order) => (
          <div key={order._id} className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex flex-wrap justify-between items-start gap-4 mb-6">
              <div>
                <div className="text-sm text-gray-500">
                  {new Date(order.createdAt).toLocaleDateString()}
                </div>
                <h2 className="text-xl font-semibold">{order.orderStatus}</h2>
              </div>
              <div className="text-right">
                <button className="text-sm text-gray-600 hover:text-black">
                  Order Details
                </button>
                <div className="mt-1">
                  <button className="text-sm text-gray-600 hover:text-black">
                    Cancel / Modify
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-8">
              <div className="w-full sm:w-auto">
                {/* Dynamically fetch and display the product image for each order item */}
                {order.items.map((item) => (
                  <img
                    key={item._id}
                    src={getProductImage(item.productId)} // Fetch the image URL dynamically based on productId
                    alt={item.title}
                    width={200}
                    height={250}
                    className="w-full sm:w-[200px] h-[250px] object-cover bg-gray-100"
                  />
                ))}
              </div>

              <div className="flex-1">
                <div className="mb-4">
                  <div className="text-sm text-gray-500">Order Id #</div>
                  <div>{order._id}</div>
                </div>
                <div className="mb-4">
                  <div className="text-sm text-gray-500">Order Total</div>
                  <div>₹{order.totalPrice}</div>
                </div>

                {order.items.map((item) => (
                  <div key={item._id} className="mb-4">
                    <div className="text-sm text-gray-500">Product</div>
                    <div className="text-lg font-semibold">{item.title}</div>
                    <div className="text-sm text-gray-500">Quantity</div>
                    <div>{item.quantity}</div>
                    <div className="text-sm text-gray-500">Size</div>
                    <div>{item.size}</div>
                  </div>
                ))}

                <div className="mb-4">
                  <div className="text-sm text-gray-500">Payment Method</div>
                  <div>{order.paymentMethod}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
