import React, { useState, useEffect } from "react";
import axios from "axios";
// import ProfilePicture from "./ProfilePicture";

export default function AccountSettingsPage() {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    dob: "",
    gender: "",
    password: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/user/${storedUserData._id}`)
      .then((response) => {
        if (response.data.status === "true") {
          const userData = response.data.data;

          // Format DOB as yyyy-mm-dd (if it exists)
          let formattedDob = "";
          if (userData.dob) {
            const date = new Date(userData.dob);
            formattedDob = date.toISOString().split("T")[0]; // Formats as yyyy-mm-dd
          }

          setUserDetails({
            ...userData,
            dob: formattedDob,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user details", error);
      });
  }, []);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateProfileDetails = () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();

    const updatedData = {};

    // Format DOB as yyyy-mm-dd
    if (userDetails.dob) {
      const formattedDate = userDetails.dob; // Assume dob is already in yyyy-mm-dd format
      formData.append("dob", formattedDate);
      updatedData.dob = formattedDate;
    }

    if (userDetails.name !== "") {
      formData.append("name", userDetails.name);
      updatedData.name = userDetails.name;
    }
    if (userDetails.email !== "") {
      formData.append("email", userDetails.email);
      updatedData.email = userDetails.email;
    }
    if (userDetails.phoneNumber !== "") {
      formData.append("phoneNumber", userDetails.phoneNumber);
      updatedData.phoneNumber = userDetails.phoneNumber;
    }
    if (userDetails.alternatePhoneNumber !== "") {
      formData.append("alternatePhoneNumber", userDetails.alternatePhoneNumber);
      updatedData.alternatePhoneNumber = userDetails.alternatePhoneNumber;
    }
    if (userDetails.gender !== "") {
      formData.append("gender", userDetails.gender);
      updatedData.gender = userDetails.gender;
    }
    if (userDetails.password !== "") {
      formData.append("password", userDetails.password);
      updatedData.password = userDetails.password;
    }

    console.log("Updated data:", updatedData);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/auth/user/edit/${storedUserData._id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response.data.status) {
          alert("Profile updated successfully!");
          setIsEditing(false);
        } else {
          alert("Failed to update profile.");
        }
      })
      .catch((error) => {
        console.error("Error updating profile", error);
      });
  };

  return (
    <div className="max-w-4xl mx-auto pt-4">
      <h1 className="text-4xl font-bold  text-center">ACCOUNT SETTINGS</h1>
      <div className="flex items-start space-x-6">
        {/* <ProfilePicture /> */}
        <div className="flex-1">
          <form className="space-y-6" style={{ padding: "20px" }}>
            <div>
              <label className="block text-sm text-gray-500 mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={userDetails.name}
                onChange={handleFieldChange}
                disabled={!isEditing}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={userDetails.email}
                  onChange={handleFieldChange}
                  disabled={!isEditing}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={userDetails.phoneNumber}
                  onChange={handleFieldChange}
                  disabled={!isEditing}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm text-gray-500 mb-1">
                Alternate Mobile Number
              </label>
              <input
                type="tel"
                name="alternatePhoneNumber"
                value={userDetails.alternatePhoneNumber || ""}
                onChange={handleFieldChange}
                disabled={!isEditing}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
                placeholder="+1 234 567 8901"
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Birthday
                </label>
                <input
                  type="date"
                  name="dob"
                  value={userDetails.dob || ""}
                  onChange={handleFieldChange}
                  disabled={!isEditing}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Gender
                </label>
                <select
                  name="gender"
                  value={userDetails.gender}
                  onChange={handleFieldChange}
                  disabled={!isEditing}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm text-gray-500 mb-1">
                Password
              </label>
              <input
                type="password"
                name="password"
                value={userDetails.password}
                onChange={handleFieldChange}
                disabled={!isEditing}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-black"
              />
            </div>

            <div className="flex items-center justify-between ">
              {!isEditing ? (
                <button
                  type="button"
                  onClick={() => setIsEditing(true)}
                  className="w-full bg-black text-white py-3 rounded-md hover:bg-gray-800 transition-colors duration-200"
                >
                  EDIT PROFILE
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleUpdateProfileDetails}
                  className="w-full bg-black text-white py-3 rounded-md hover:bg-gray-800 transition-colors duration-200"
                >
                  SAVE CHANGES
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
